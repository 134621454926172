import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'
import SubNav from '../SubNav';

class NavItem extends Component {
  render() {
    const { title, url, subRoutes, route } = this.props;
    const active = route === url;
    return subRoutes && subRoutes.length ? (<SubNav {...this.props} />) : (
      <div className={'nav-item'}>
        <Link to={url} className={active ? 'active' : ''}>
          <span className='nav-title'>{title}</span>
        </Link>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  route: state.router.location.pathname,
})

export default connect(mapStateToProps, {})(NavItem)
