import { programs } from '../config/programs';

const INITIAL_STATE = {
  config: {}
};

export default (state = INITIAL_STATE, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      var location = action.payload.location.pathname;
      if (location) {
        newState.programs = programs.filter(c => location.includes(c.category))
      }
      break;
    default:
      return newState;
  }

  return newState;
};
