import axios from 'axios';

export const loadData = token => dispatch => {
  console.log('loading data');
  axios.get(`/api${token}`)
    .then(({ data: payload }) => dispatch({ type: 'LOAD_DATA', payload }))
    .catch(error => {
      localStorage.removeItem('token');
      console.log({error})
    })
}
