const widthState = (width, insightPanelOpen) => {
  if(width > 1800) {
    return 'default'
  } else if ((width < 1800 && width > 1550) && insightPanelOpen === true) {
    return 'side-nav-slide'
  } else if ((width < 1800 && width > 1550) && insightPanelOpen === false) {
    return 'default'
  } else {
    return 'side-nav-slide'
  }
}

const INITIAL_STATE = {
  open: false,
  detail: null,
  width: window.innerWidth,
  sideNavClass: widthState(window.innerWidth),
};

export default (state = INITIAL_STATE, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'TOGGLE_INSIGHTS':
      newState.open = !state.open;
      newState.sideNavClass = widthState(state.width, newState.open);
      newState.detail = null;
      break
    case 'CLOSE_INSIGHTS':
      newState.open = false;
      newState.detail = null;
      break
    case 'INSIGHT_DETAILS':
      newState.detail = action.payload;
      newState.open = true;
      break
    case 'CLOSE_INSIGHT_DETAILS':
      newState.detail = null;
      break
    case "UPDATING_WINDOW_SIZE":
      newState.width = action.width;
      newState.sideNavClass = widthState(action.width, state.open);
      break;
    default:
      return newState;
  }

  return newState;
};
