import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import insightReducer from './insight'
import dashboardReducer from './dashboard'
import dataReducer from './data'
import programReducer from './programs'

const rootReducer = (history) => combineReducers({
  insight: insightReducer,
  dashboard: dashboardReducer,
  data: dataReducer,
  program: programReducer,
  router: connectRouter(history)
})

export default rootReducer
