import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Icon';
import { toggleInsights } from '../../actions/controls';
import close from '../../assets/icons/close.svg';
import InsightRow from './insight-row.js';
import InsightDetail from './insight-details.js';

class Insights extends Component {

  render() {
    const {
      open,
      insights,
      insightDetail,
      toggleInsights
    } = this.props;
    return open ? (
      <div className='insights-panel'>
        {insightDetail ? (
          <InsightDetail detail={insightDetail}/>
        ) : (
          <div className='insight-container'>
            <div className='close-button' onClick={toggleInsights}>
              <Icon
                icon={close}
                alt='x'
              />
            </div>
            <h2>Key Insights</h2>
            <div className='insight-list'>
              {insights.map((c, idx) => (
                <InsightRow
                  key={`insight_${idx}`}
                  rowId={idx}
                  data={c} />
              ))}
            </div>
          </div>
        )}
      </div>
    ) : <div/>;
  }
}

const mapStateToProps = state => ({
  ...state.insight,
  insights: state.data.insights,
  location: state.router.location.pathname,
  insightDetail: state.insight.detail
})

const mapDispatchToProps = dispatch => ({
  toggleInsights: () => dispatch(toggleInsights())
})

export default connect(mapStateToProps, mapDispatchToProps)(Insights)
