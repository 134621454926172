import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux'

const SubRoute = ({url, title, index, active}) => (
  <Link className={`nav-item  ${active ? 'active' : ''}`} to={url} key={`sub${index}`}>
    <span className='nav-title'>{title}</span>
  </Link>
)

class SubNav extends Component {
  constructor() {
    super()
    this.state = {
      open: true
    }
  }

  toggleDropdown = () => {
    this.setState({
      open: !this.state.open
    })
  }

  render() {
    const { title, icon, subRoutes, route, url } = this.props;
    const { open } = this.state;
    const active = route === url;
    return (
      <div className={`nav-item dropdown ${open && 'open'}`}>
        <Link
          to={url}
          className={active ? 'sub-nav active' : 'sub-nav '}
          onClick={this.toggleDropdown}
        >
          <img
            className="nav-icon"
            src={icon}
            alt={title}
          />
          <span className='nav-title'>{title}</span>
          <div className="dropdown-arrow" />
        </Link>

        <div className='dropdown-content'>
          {subRoutes.map((sub, index) => SubRoute({ ...sub, index, active: route === sub.url }))}
        </div>

      </div>
    );
  }
}

const mapStateToProps = state => ({
  route: state.router.location.pathname,
})

export default connect(mapStateToProps, {})(SubNav)
