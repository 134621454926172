import {
  filterOpiate,
  filterOpiateDim2,
  uniqueSet
} from '../../data/dataCore';

export function opiatePatients() {
  // Opiate Usage
  const {
    toYear,
    toQuarter,
    rangeData
  } = this.props.data
  const {
    currentData,
    compareData,
    benchData,
    compareBenchData,
  } = rangeData;

  const cur = filterOpiate(currentData, toYear, toQuarter).filter(d => d.Dimension_1 === 'Percent of Membership');
  const com = filterOpiate(compareData, toYear - 1, toQuarter).filter(d => d.Dimension_1 === 'Percent of Membership');
  const bnc = filterOpiate(benchData, toYear, toQuarter).filter(d => d.Dimension_1 === 'Percent of Membership');
  const cbn = filterOpiate(compareBenchData, toYear - 1, toQuarter).filter(d => d.Dimension_1 === 'Percent of Membership');

  const curMM = filterOpiateDim2(cur, 'Unique Members');
  const comMM = filterOpiateDim2(com, 'Unique Members');
  const bncMM = filterOpiateDim2(bnc, 'Unique Members');
  const cbnMM = filterOpiateDim2(cbn, 'Unique Members');

  const oneOrMore = '1. Percent of Members Prescribed One or More Opioids';
  const cronic = '2. Percent of Members Prescribed Opioids Who Are Considered Chronic Opioid Users';
  const sedative = '3. Percent of Members Prescribed Opioids Who Are Also Prescribed Sedatives';

  return [{
    name: oneOrMore,
    value: (filterOpiateDim2(cur, oneOrMore)) / curMM * 100,
    compareValue: (filterOpiateDim2(com, oneOrMore)) / comMM * 100,
    benchmark: (filterOpiateDim2(bnc, oneOrMore)) / bncMM * 100,
    compareBenchmark: (filterOpiateDim2(cbn, oneOrMore)) / cbnMM * 100,
  },{
    name: cronic,
    value: filterOpiateDim2(cur, cronic) / filterOpiateDim2(cur, oneOrMore) * 100,
    compareValue: filterOpiateDim2(com, cronic) / filterOpiateDim2(com, oneOrMore) * 100,
    benchmark: filterOpiateDim2(bnc, cronic) / filterOpiateDim2(bnc, oneOrMore) * 100,
    compareBenchmark: filterOpiateDim2(cbn, cronic) / filterOpiateDim2(cbn, oneOrMore) * 100,
  },{
    name: sedative,
    value: filterOpiateDim2(cur, sedative) / filterOpiateDim2(cur, cronic) * 100,
    compareValue: filterOpiateDim2(com, sedative) / filterOpiateDim2(com, cronic) * 100,
    benchmark: filterOpiateDim2(bnc, sedative) / filterOpiateDim2(bnc, cronic) * 100,
    compareBenchmark: filterOpiateDim2(cbn, sedative) / filterOpiateDim2(cbn, cronic) * 100,
  }];
}


export function opiateScripts() {
  const {
    toYear,
    toQuarter,
    rangeData
  } = this.props.data
  const {
    currentData,
    compareData,
    benchData,
    compareBenchData,
  } = rangeData;

  const cur = filterOpiate(currentData, toYear, toQuarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const com = filterOpiate(compareData, toYear - 1, toQuarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const bnc = filterOpiate(benchData, toYear, toQuarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const cbn = filterOpiate(compareBenchData, toYear - 1, toQuarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const opiateMM = arr => arr.reduce((t, n) => t + n.Member_Month_Count, 0)

  const supply = uniqueSet(cur, 'Dimension_2');

  const graphData = supply.map(days => ({
    name: days,
    value: filterOpiateDim2(cur, days) / opiateMM(cur) * 100,
    compareValue: filterOpiateDim2(com, days) / opiateMM(com) * 100,
    benchmark: filterOpiateDim2(bnc, days) / opiateMM(bnc) * 100,
    compareBenchmark: filterOpiateDim2(cbn, days) / opiateMM(cbn) * 100,
  }))

  return graphData;

}
