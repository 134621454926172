import React, { Component } from 'react';

export default class Toggle extends Component {

  handleSwitch = () => {
    this.props.handleChange()
  }

  render() {
    const { label, value = false } = this.props;
    return (
      <label className='toggle'>
        <div className="switch">
          <input type="checkbox" checked={value} onChange={this.handleSwitch}/>
          <span className="slider"></span>
        </div>
        <span className={`label ${value && 'active'}`}>{label}</span>
      </label>
    );
  }
}
