import React, { Component } from 'react';
import Graph from '../Graph';
import { connect } from 'react-redux';
import { claimsSet, handleTab, higherMetric } from './core'

class Claim extends Component {

  claimsSet = claimsSet.bind(this)
  handleTab = handleTab.bind(this)
  higherMetric = higherMetric.bind(this)

  render() {
    const { tab = 0 } = this.state || {};
    const graphSet = this.claimsSet();

    return (
      <div className='dash-page'>
        <h1>Claim Costs</h1>
        <Graph
          label={'Claim Category'}
          tabs={['PMPM', 'PAID PER UTIL', 'TOTAL PAID', '# OF SERVICES']}
          handleTab={this.handleTab}
          tab={tab}
          format={['$.', '$', '$', 'n'][tab]}
          metric={this.higherMetric()}
          graphData={graphSet}
          allowBench={true}
          allowCompare={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data,
  title: state.dashboard.config.title
})

export default connect(mapStateToProps, {})(Claim)
