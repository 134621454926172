// const divisor = (val) =>  0.3 * Math.pow(10, Math.ceil(val).toString().length);

export const renderTicks = ( data, compare, bench, format ) => {
  if (format === '%') {
    return [0, 33.3, 66.6, 100]
  } else {
    const values = data.map(v => v.value).concat(
      compare ? data.map(v => v.compareValue) : []
    ).concat(
      bench ? data.map(v => v.benchmark) : []
    ).concat(
      bench && compare ? data.map(v => v.compareBenchmark) : []
    );
    let highValue = 0;
    values.forEach(v => v > highValue ? highValue = v : true);
    const tick = Math.ceil(highValue * 1.25 / 3);
    return [0, tick, tick * 2, tick * 3]
  }
}

export function formatValues(n, isTick, isBench) {
  const { format = 'n' } = this.props || {}
  if (isNaN(n) || n === Infinity) {
    return 'No Data'
  }
  let val = '';
  if (isBench) {
    if (val === 0 || val === '0') {
      return 'No benchmarks for this dimension'
    }
    val = n <= 0 ? '+' : '-';
  }
  if (
    format === '$' ||
    format === '$.'
  ) {
    val += '$'
  }

  if (
    (format === '$.' && !isTick) ||
    format === '.'
  ) {
    val += Math.abs(n).toFixed(2);
  } else if (
    format === '%' ||
    format === '%%'
  ) {
    val += Math.abs(n).toFixed(1);
  } else {
    val += Math.round(Math.abs(n)).toLocaleString();
  }

  if (
    format === '%' ||
    format === '%%'
  ) {
    val = val + '%'
  }
  return val;
}
