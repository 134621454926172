import {
  totalPlanPaid_dim1,
  utilCount_dim1,
  paidPerUtil_dim1,
  uniqueSet
} from '../../data/dataCore';

export function claimsSet(){
  var tabs = [ 'perMember', 'perUtil', 'paid', 'services' ];
  const { tab = 0 } = this.state || {};
  const {
    metricData,
    memberMonths: {
      current: curMM,
      compare: comMM
    }
  } = this.props.data;
  const {
    currentData: cur,
    compareData: com,
    benchData: bnc,
    compareBenchData: cbn
  } = metricData;

  const claims = uniqueSet(cur, 'Dimension_1');

  var graphSet =  claims.sort().map((claim) => ({
    perMember: {
      name: claim,
      reverse: claim === '4. Preventive Services',
      value: totalPlanPaid_dim1(cur, claim) / curMM,
      compareValue: totalPlanPaid_dim1(com, claim) / comMM,
      benchmark: totalPlanPaid_dim1(bnc, claim) / curMM,
      compareBenchmark: totalPlanPaid_dim1(cbn, claim) / comMM
    },
    perUtil: {
      name: claim,
      reverse: claim === '4. Preventive Services',
      value: paidPerUtil_dim1(cur, claim),
      compareValue: paidPerUtil_dim1(com, claim),
      benchmark: paidPerUtil_dim1(bnc, claim),
      compareBenchmark: paidPerUtil_dim1(cbn, claim)
    },
    paid: {
      name: claim,
      reverse: claim === '4. Preventive Services',
      value: totalPlanPaid_dim1(cur, claim),
      compareValue: totalPlanPaid_dim1(com, claim),
      benchmark: totalPlanPaid_dim1(bnc, claim),
      compareBenchmark: totalPlanPaid_dim1(cbn, claim)
    },
    services: {
      name: claim,
      reverse: claim === '4. Preventive Services',
      value: utilCount_dim1(cur, claim),
      compareValue: utilCount_dim1(com, claim),
      benchmark: utilCount_dim1(bnc, claim),
      compareBenchmark: utilCount_dim1(cbn, claim)
    },
  })).map(g => g[tabs[tab]])

  return graphSet;
}

export function higherMetric() {
  const { tab = 0 } = this.state || {};
  const {
    metricData: {
      currentData: cur,
      compareData: com,
    },
    memberMonths: {
      current: curMM,
      compare: comMM
    },
    months
  } = this.props.data;
  return [
    {
      title: 'PMPM Total',
      value: '$' + ((cur.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0) / curMM)).toFixed(2).toLocaleString(),
      compareValue: '$' + ((com.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0) / comMM)).toFixed(2).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total paid per util',
      value: '$' + Math.round((cur.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0) / cur.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      compareValue: '$' + Math.round((com.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0) / com.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'total paid',
      value: '$' + Math.round(cur.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0)).toLocaleString(),
      compareValue: '$' + Math.round(com.reduce((t, n) => t + (n.Plan_Paid === null ? 0 : n.Plan_Paid), 0)).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total services',
      value: cur.reduce((t, n) => t + n.Util_Count, 0).toLocaleString(),
      compareValue: com.reduce((t, n) => t + n.Util_Count, 0).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    }
  ][tab]
}

export function handleTab(i) {

  this.setState({
    tab: i
  })
}
