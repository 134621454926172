import React, { Component } from 'react';

export default class Icon extends Component {

  constructor() {
    super()
    this.state = {
      hover: false
    }
  }

  hoverIn = () => this.setState({ hover: true })

  hoverOut = () => this.setState({ hover: false })


  render() {
    const {
      icon,
      alt,
      hoverIcon,
      tooltip,
      badge,
      className,
      styles,
      onClick
    } = this.props;
    const { hover } = this.state || {};
    return (
      <div
        className={`icon ${className}`}
        onMouseOver={this.hoverIn}
        onMouseOut={this.hoverOut}
        onClick={onClick}
        styles={styles}>
        <img
          src={hover && hoverIcon ? hoverIcon : icon}
          alt={alt || ' '}
        />
        {
          badge ? (
            <div className='badge'>
              {badge}
            </div>)
          : null
        }
        {hover && tooltip ? (
          <div className='tooltip'>
            <p>{tooltip}</p>
          </div>
        ) : null}
      </div>
    );
  }
}
