import React, { Component } from 'react';
import Graph from '../Graph';
import { connect } from 'react-redux';
import {
  graphData,
  handleMentalTab,
  handleSubstanceTab,
  higherMetric
} from './core'

class Behavioral extends Component {

  graphData = graphData.bind(this)
  handleMentalTab = handleMentalTab.bind(this)
  handleSubstanceTab = handleSubstanceTab.bind(this)
  higherMetric = higherMetric.bind(this)

  render() {
    const {
      mentalTab = 0,
      substanceTab = 0
    } = this.state || {};
    return (
      <div className='dash-page'>
        <h1>Behavioral Health</h1>
        <Graph
          label={'Mental Health'}
          info={'This is by Mental Health'}
          tabs={['PMPM', 'PAID PER UTIL', 'TOTAL PAID', 'VISITS']}
          format={['$.', '$', '$', 'n'][mentalTab]}
          handleTab={this.handleMentalTab}
          tab={mentalTab}
          graphData={this.graphData('Mental Health')}
          metric={this.higherMetric('Mental Health')}
          allowBench={true}
          allowCompare={true}
        />
        <Graph
          label={'Substance Abuse'}
          info={'This is by Substance Abuse'}
          tabs={['PMPM', 'PAID PER UTIL', 'TOTAL PAID', 'VISITS']}
          format={['$.', '$', '$', 'n'][substanceTab]}
          handleTab={this.handleSubstanceTab}
          tab={substanceTab}
          graphData={this.graphData('Substance Abuse')}
          metric={this.higherMetric('Substance Abuse')}
          allowBench={true}
          allowCompare={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, {})(Behavioral)
