import React, { Component } from 'react';
import Button from '../Button';

export default class Slide extends Component {

  render() {
    const { title, description, image, link } = this.props;
    return (
      <div className='slide'>
        <div className='slide-img'>
          <img src={image} alt='health-app'/>
        </div>
        <div className='slide-info'>
          <h2>{title}</h2>
          <p>{description}</p>
          <Button link={link} target='_blank' label='learn more'/>
        </div>
      </div>
    );
  }
}
