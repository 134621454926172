import React from 'react'
import { Route, Switch } from 'react-router'
import AppContainer from '../components/AppContainer'
import NavBar from '../components/NavBar'
import Header from '../components/Header'
import Insights from '../components/Insights'
import Membership from '../components/Membership'
import ClaimCost from '../components/ClaimCost'
import Emergency from '../components/Emergency'
import Behavioral from '../components/Behavioral'
import Opiate from '../components/Opiate'
import Programs from '../components/Programs'
import TintScreen from '../components/TintScreen'


const routes = (
  <AppContainer>
    <Header />
    <TintScreen/>
    <div className='app-content-wrap'>
      <NavBar />
      <div className='app-page-container'>
        <div className='page-container'>
          <Switch>
            <Route path="/membership" component={Membership} />
            <Route path="/claim-cost" component={ClaimCost} />
            <Route path="/emergency-room" component={Emergency} />
            <Route path="/behavioral" component={Behavioral} />
            <Route path="/opioids" component={Opiate} />
          </Switch>
          <Programs />
        </div>
        <Insights />
      </div>
    </div>
  </AppContainer>
)

export default routes
