import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { loadData } from '../../actions/data'
import logo from '../../assets/FCH_2019_Logo_COLOR_CORPORATE.jpg';

class AppContainer extends Component {
  componentWillMount() {
    console.log(this.props);
    const { search } = window.location;
    let token = localStorage.token;
    if (search) {
      token = search
        .substring(1)
        .split('&')
        .reduce((t, q) => q.split('=')[0] === t + 'token' ? `?${q}` : t + '', '');
      localStorage.token = token;
    }
    if (token && token !== 'null') {
      this.props.loadData(token);
      if (this.props.pathname.length < 2) {
        window.location.replace('/membership')
      }
    }
  }

  render() {
    const { children, insightsOpen } = this.props || {};
    return localStorage.token ? (
      <div className={`app-container ${insightsOpen && 'insights-open'}`}>
        {children}
      </div>
    ) : (
      <div className='app-container'>
        <div className='logged-out'>
          <div className='logo'>
            <img src={logo} alt='First Choice'/>
          </div>
        </div>
        <div className='lo-info'>
          <div className='lo-modal'>
            <h2>Sign In to Continue</h2>
            <p>
              Please sign in again to continue. If you were previously signed in we logged you out to keep your account safe.
            </p>
            <a href="https://www.fchn.com/ClientPortal/Home/EmployerDashboard">SIGN IN</a>
          </div>
        </div>

      </div>
    );
  }
}

const mapDispatchToProps = dispatch => bindActionCreators({ loadData }, dispatch);

const mapStateToProps = state => ({
  insightsOpen: state.insight.open,
  pathname: state.router.location.pathname
})

export default connect(mapStateToProps, mapDispatchToProps)(AppContainer)
