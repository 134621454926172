import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showDetails } from '../../actions/controls';

class InsightRow extends Component {

  handleClick = () => {
    const { data, showDetails } = this.props;
    showDetails(data);
  }

  render() {
    const {
      data: {
        color,
        dim_description,
        dimension,
        tab,
        value,
        format
      },
      rowId,
      dateRange
    } = this.props;
    const label = dim_description.indexOf('.') > 0 ? dim_description.split('.')[1] : dim_description;
    return (
      <div onClick={this.handleClick}>
        <div className='insight-row' id={rowId}>
          <div className='insight-dot' style={{ background: color, width: '8px' }}/>
          <div className='insight-blurb'>
            <p>{dimension} {label} {tab} cost is {value >= 0 ? 'above' : 'below'} benchmark value by {format}</p>
            <h5>{dateRange}</h5>
          </div>
        </div>
      </div>
    );
  }
}



const mapStateToProps = state => {
  const {
    fromYear,
    fromQuarter,
    toYear,
    toQuarter,
  } = state.data;
  return ({
    detail: state.insight.detail,
    dateRange: `Q${fromQuarter} ${fromYear} - Q${toQuarter} ${toYear}`
  })
}

const mapDispatchToProps = dispatch => ({
  showDetails: (obj) => dispatch(showDetails(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InsightRow)
