import React, { Component } from 'react';
import increase from '../../assets/icons/increase.svg';
import decrease from '../../assets/icons/decrease.svg';
import incNeutral from '../../assets/icons/incNeutral.svg';
import decNeutral from '../../assets/icons/decNeutral.svg';

export default class Metric extends Component {

  render() {
    const {
      compare,
      metric,
      neutral
    } = this.props || {}
    const {
      title,
      value = '0',
      compareValue = '0',
      subtitle,
    } = metric || {};
    const val = parseFloat(value.replace('$', '').replace(/,/g, ''));
    const cval = parseFloat(compareValue.replace('$', '').replace(/,/g, ''));

    const dec = neutral ? decNeutral : decrease;
    const inc = neutral ? incNeutral : increase;

    const compareMetric = (Math.round((val - cval) /  cval * 1000) / 10).toFixed(1);
    return title ? compare ? (
      <div className='metric'>
        <h4 className='metric-title'>{title}</h4>
        <h2 className='metric-value'>{compareMetric}% {parseInt(compareMetric * 10) === 0 ? '' : <img src={compareMetric < 0 ? dec : inc} alt=''/>}</h2>
        <h4 className='metric-subititle'>current {value} vs. previous {compareValue}</h4>
      </div>
    ) : (
      <div className='metric'>
        <h4 className='metric-title'>{title}</h4>
        <h2 className='metric-value'>{value}</h2>
        <h4 className='metric-subititle'>{subtitle}</h4>
      </div>
    ) : <div/>;
  }
}
