export const pageConfig = [
  {
    title: 'Membership',
    url: '/',
    metric: 'Member Months'
  },
  {
    title: 'Membership',
    url: '/membership',
    metric: 'Member Months'
  },
  {
    title: 'Risk Score',
    url: '/risk-score'
  },
  {
    title: 'Claim Costs',
    url: '/claim-cost',
    metric: 'Claim Cost'
  },
  {
    title: 'Emergency Room Metrics',
    url: '/emergency-room',
    metric: 'ER Utilization'
  },
  {
    title: 'Behavioral Health',
    url: '/behavioral',
    metric: 'Behavioral Health Utilization'
  },
  {
    title: 'Dashboard Home',
    url: '/dashboard'
  },
  {
    title: 'Professional',
    url: '/claim-cost/professional',
    metric: 'Claim Cost',
    dim1: 'Professional',
  },
  {
    title: 'Prescription Drugs',
    url: '/claim-cost/prescription',
    metric: 'Claim Cost',
    dim1: 'Prescription Drugs',
  },
  {
    title: 'Preventive',
    url: '/claim-cost/preventive',
    metric: 'Claim Cost',
    dim1: 'Preventive',
  },
  {
    title: 'Ancillary',
    url: '/claim-cost/ancillary',
    metric: 'Claim Cost',
    dim1: 'Ancillary',
  },
  {
    title: 'Inpatient(Other)',
    url: '/claim-cost/inpatient-other',
    metric: 'Claim Cost',
    dim1: 'Inpatient - Other',
  },
  {
    title: 'Inpatient(Acute)',
    url: '/claim-cost/inpatient-acute',
    metric: 'Claim Cost',
    dim1: 'Inpatient - Acute',
  },
  {
    title: 'Outpatient',
    url: '/claim-cost/outpatient',
    metric: 'Claim Cost',
    dim1: 'Outpatient'
  }
]
