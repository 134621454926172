import React, { Component } from 'react';

export default class Tabs extends Component {

  handleTab(tabIndex) {
    const { handleChange } = this.props;
    handleChange && handleChange(tabIndex);
  }

  render() {
    const {
      labels = [],
      content = [],
      tab
    } = this.props;
    return (
      <div className='tabs'>
        <div className='tab-bar'>
          {labels.map((label,i) => (
            <div
              className={`tab ${tab === i && labels.length !== 1 ? 'active-tab' : ''}`}
              key={`tab${i}`}
              onClick={this.handleTab.bind(this, i)}
            >
              {label}
            </div>
          ))}
        </div>
        {content.map((tabContent, i) => (
          <div
            className={`tab-content ${tab === i ? 'active-tab-content' : ''}`}
            key={`tab-content${i}`}
          >
            {tabContent}
          </div>
        ))}
      </div>
    );
  }
}
