import React, { Component } from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { connect } from 'react-redux';
import { closeDetails, toggleInsights } from '../../actions/controls';
import Icon from '../Icon';
import Button from '../Button';
import close from '../../assets/icons/close.svg';
import back from '../../assets/icons/back.svg';

class InsightDetails extends Component {

  render() {
    const {
      detail: {
        dim_description,
        dimension,
        link,
        tab,
        value,
        format,
        color,
        detailRows = [
          {
            metric: tab,
            value: format,
            bench: format,
          },
          {
            metric: tab,
            value: format,
            bench: format,
          },
          {
            metric: tab,
            value: format,
            bench: format,
          }
        ]
      },
      dateRange,
      closeInsights,
      goBack
    } = this.props;
    const label = dim_description.indexOf('.') > 0 ? dim_description.split('.')[1] : dim_description;
    return (
      <div className='insight-container'>
        <div className='detail-bar'>
          <div className='back-button' onClick={goBack}>
            <Icon
              icon={back}
              alt='<'
            />
            <span>Back</span>
          </div>
          <div className='close-button' onClick={closeInsights}>
            <Icon
              icon={close}
              alt='x'
            />
          </div>
        </div>
        <div className='detail-title'>
          <h3>
            {dimension} {label} {tab} cost is {value >= 0 ? 'above' : 'below'} benchmark value by {format}
          </h3>
          <span>
            { dateRange }
          </span>
        </div>
        <div className='detail-table'>
          <div className='detail-table-row detail-table-header'>
            <div className='detail-table-metric'>
              metric
            </div>
            <div className='detail-table-value'>
              value
            </div>
            <div className='detail-table-bench'>
              bench
            </div>
          </div>
          {detailRows.map((dr, i) => (
            <div key={`dr_${i}`} className={`detail-table-row ${i % 2 ? 'odd' : ''}`}>
              <div className='detail-table-metric'>
                {dr.metric}
              </div>
              <div className='detail-table-value'>
                {dr.value}
              </div>
              <div className={`detail-table-bench ${i === 0 ? 'insight-bench' : ''}`} style={i === 0 ? { color } : {}}>
                {dr.bench}
              </div>
            </div>
          ))}
        </div>
        <Link className='view-report' smooth to={link} scroll={el => { el.scrollIntoView(true); window.scrollBy(0, -85) }}>
          <Button label={'VIEW REPORT'}/>
        </Link>
      </div>
    )
  }
}

const mapStateToProps = state => {
  const {
    fromYear,
    fromQuarter,
    toYear,
    toQuarter,
  } = state.data;
  return ({
    dateRange: `Q${fromQuarter} ${fromYear} - Q${toQuarter} ${toYear}`
  })
}

const mapDispatchToProps = dispatch => ({
  goBack: () => dispatch(closeDetails()),
  closeInsights: () => dispatch(toggleInsights())
})

export default connect(mapStateToProps, mapDispatchToProps)(InsightDetails)
