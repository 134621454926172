import { pageConfig } from '../config/dashboard';

const INITIAL_STATE = {
  config: {},
  sideNav: false
};

export default (state = INITIAL_STATE, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case '@@router/LOCATION_CHANGE':
      var location = action.payload.location.pathname;
      newState.config = pageConfig.filter(c => c.url === location)[0] || {};
      break;
    case 'CLOSE_DRAWERS':
      newState.sideNav = false;
      break;
    case 'TOGGLE_SIDE_NAV':
      newState.sideNav = !state.sideNav
      break;
    case 'CLOSE_SIDE_NAV':
      newState.sideNav = false
      break;
    default:
      return newState;
  }

  return newState;
};
