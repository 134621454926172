import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showDetails } from '../../actions/controls';

class DataTooltip extends Component {

  onMouseMove = (e) => {
    var bounds = e.target.getBoundingClientRect();
    var left = e.clientX - bounds.left + 20;
    var top = e.clientY - bounds.top + 20;
    this.setState({ left, top });
  }

  render() {
    const {
      open,
      // data,
      message
    } = this.props;

    const { left = -40, top = 200 } = this.state || this.props || {};

    return open && message ? (
      <div className={'data-row-tooltip'}  onMouseMove={this.onMouseMove}>
        <div className='tooltip'  style={{left, top}}>
         {message}
        </div>
      </div>
    ) : <div/>;
  }
}

const mapStateToProps = state => {
  const {
    fromQuarter,
    fromYear,
    toQuarter,
    toYear,
  } = state.data;
  return ({
    range: `Q${fromQuarter}, ${fromYear} - Q${toQuarter}, ${toYear}`,
    compareRange: `Q${fromQuarter}, ${fromYear - 1} - Q${toQuarter}, ${toYear - 1}`,
    insights: state.data.insights
  })
}

const mapDispatchToProps = dispatch => ({
  showDetails: (obj) => dispatch(showDetails(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DataTooltip)
