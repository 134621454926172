import React, { Component } from 'react';
import { formatValues } from './core';

export default class Ticks extends Component {
  formatValues = formatValues.bind(this)

  render() {
    const { data } = this.props;
    return (
      <div className='ticks'>
        {data.map((tick, i) => (
          <div key={`tick_${i}_${tick}`} className='tickbox'>
            <div className='tick-label-top'>{this.formatValues(tick, true)}<div className='strong-tick'/></div>
            <div className='tick-label-bottom'>{this.formatValues(tick, true)}<div className='strong-tick'/></div>
          </div>
        ))}
      </div>
    );
  }
}
