import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Icon';
import Bar from './bar';
import Tooltip from './tooltip';
import InsightTip from './insightsTip';
import increase from '../../assets/icons/increase.svg';
import decrease from '../../assets/icons/decrease.svg';
import incNeutral from '../../assets/icons/incNeutral.svg';
import decNeutral from '../../assets/icons/decNeutral.svg';
import { formatValues } from './core';

const CompareStat = ({bench, reverse, neutral}) => {
  const dec = neutral ? decNeutral : decrease;
  const inc = neutral ? incNeutral : increase;
  const icon = bench < 0 ? dec : inc;
  const reverseIcon = bench > 0 ? dec : inc;
  return (
    <div className={`bench-stat ${reverse ? 'reverse' : ''}`}>{(parseFloat(bench) || 0).toFixed(1)}% {Math.round(parseFloat(bench) * 10) === 0 ? '' : (<Icon icon={reverse ? reverseIcon : icon}/>)}</div>
  )
}

class Row extends Component {
  constructor() {
    super();
    this.state = {
      hover: false,
      left: 0,
      top: 0
    }
  }

  formatValues = formatValues.bind(this)

  onMouseOver = (e) => {
    var bounds = e.target.getBoundingClientRect();
    var left = e.clientX - bounds.left + 100;
    var top = e.clientY - bounds.top + 100;
    var hover = e.target.className !== 'insight-dot';
    this.setState({
      left,
      top,
      hover
    });
  }

  render() {
    const {
      data,
      index,
      max,
      range,
      compareRange,
      yRange,
      yCompareRange,
      bench,
      format,
      insights,
      insightDetail,
      yearly,
      excludeInsights,
      neutral
    } = this.props;
    const compare = this.props.compare && data.compareValue;
    const { hover, top, left } = this.state;
    const insight = insights.filter(ins => ins.dim_description === data.name);
    const selected = !excludeInsights && insightDetail && insightDetail.dim_description === data.name ? 'selected' : '';
    const label = data.name ? data.name.indexOf('.') > 0 ? data.name.split('.')[1] : data.name : '';
    const compareStat = compare ? format === '%' || format === '%%' ? (data.compareValue.toFixed(1) - data.value.toFixed(1)) * -1  : (Math.round(((data.value - data.compareValue) / data.compareValue * 10000)) / 100).toFixed(2) : 0;

    return (
      <div
        onMouseOver={this.onMouseOver}
        onMouseOut={() => this.setState({hover: false})}
        className={`row ${index && index % 2 ? 'odd' : ''} ${hover ? 'hover' : ''} ${compare ? 'compare' : ''} ${selected}`}>
        {excludeInsights ? '' : <InsightTip insight={insight.length ? insight[0] : null} />}
        <Tooltip {...this.props} open={hover} top={top} left={left} />
        {compare ? (
          <div className='label-section'>
            <div className='graph-label'>
              {index || index === 0 ? (
                <span className='graph-number'>{index + 1}.</span>
              ) : null}
              <span className='name-label'>{label}</span>
              <CompareStat reverse={data.reverse} bench={compareStat} neutral={neutral} />
            </div>
          </div>
        ) : null}
        <div className='bar-section'>
          <div className='label-section'>
            {compare ? (
              <div className='graph-label range-label'>
                <span className='name-label'>{yearly ? yRange: range }</span>
              </div>
            ) : (
              <div className='graph-label'>
                {index || index === 0  ? (
                  <span className='graph-number'>{index + 1}.</span>
                ) : null}
                <span className='name-label'>{label}</span>
                <span className='graph-sub-label'>{data.subLabel}</span>
              </div>
            )}
            <span className='bar-label'>{this.formatValues(data.value)}</span>
          </div>
          <Bar reverse={data.reverse} benchOn={bench} bench={bench && data.benchmark} value={data.value} max={max} format={format}/>
        </div>
        {compare ? (
          <div className='bar-section'>
            <div className='label-section'>
              <div className='graph-label range-label'>
                <span className='name-label'>{yearly ? yCompareRange : compareRange}</span>
              </div>
              <span className='bar-label'>{this.formatValues(data.compareValue)}</span>
            </div>
            <Bar reverse={data.reverse} benchOn={bench} bench={bench && data.compareBenchmark} value={data.compareValue} max={max} format={format}/>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    fromQuarter,
    fromYear,
    toQuarter,
    toYear,
  } = state.data;
  return ({
    range: `Q${fromQuarter}, ${fromYear} - Q${toQuarter}, ${toYear}`,
    compareRange: `Q${fromQuarter}, ${fromYear - 1} - Q${toQuarter}, ${toYear - 1}`,
    yRange: `Q${toQuarter === 4 ? 1 : toQuarter + 1}, ${toQuarter === 4 ? toYear : toYear - 1} - Q${toQuarter}, ${toYear}`,
    yCompareRange: `Q${toQuarter === 4 ? 1 : toQuarter + 1}, ${toQuarter === 4 ? toYear - 1 : toYear - 2} - Q${toQuarter}, ${toYear - 1}`,
    insights: state.data.insights,
    insightDetail: state.insight.detail
  })
}

export default connect(mapStateToProps, {})(Row)
