import React, { Component } from 'react';
import Graph from '../Graph';
import { connect } from 'react-redux';
import {
  planSet,
  visitSet,
  severitySet,
  handleTab,
  higherMetric
} from './core'

class Emergency extends Component {

  planSet = planSet.bind(this)
  visitSet = visitSet.bind(this)
  severitySet = severitySet.bind(this)
  handleTab = handleTab.bind(this)
  higherMetric = higherMetric.bind(this)

  render() {
    const { tab = 0 } = this.state || {}
    return (
      <div className='dash-page'>
        <h1>Emergency Room</h1>
        <Graph
          label={'ER Utilization by Plan'}
          tabs={['PMPM', 'PAID PER UTIL', 'TOTAL PAID', 'VISITS']}
          format={['$.', '$', '$', 'n'][tab]}
          handleTab={this.handleTab}
          tab={tab}
          graphData={this.planSet()}
          metric={this.higherMetric()}
          allowBench={true}
          allowCompare={true}
        />
        <Graph
          label={'Percent of Members by ER Visit Frequency'}
          yearly={true}
          format={'%'}
          tabs={['% MEMBERS']}
          graphData={this.visitSet()}
          allowBench={true}
          allowCompare={true}
        />
        <Graph
          label={'Percent of ER Visits by Severity'}
          info={null}
          yearly={false}
          format={'%'}
          tabs={['% VISITS']}
          graphData={this.severitySet()}
          allowBench={true}
          allowCompare={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, {})(Emergency)
