import React, { Component } from 'react';
import { closeDrawers } from '../../actions/controls'
import { connect } from 'react-redux'

class TintScreen extends Component {

  render() {
    const { open, closeDrawers } = this.props;
    document.body.style.overflow = open ? 'hidden' : 'auto';

    return (
      <div
        className={`tint-screen ${open && 'open'}`}
        onClick={closeDrawers}
      />
    )
  }
}

const mapStateToProps = state => ({
  open: state.dashboard.sideNav
})

const mapDispatchToProps = dispatch => ({
  closeDrawers: () => dispatch(closeDrawers())
})

export default connect(mapStateToProps, mapDispatchToProps)(TintScreen)
