import React, { Component } from 'react';

export default class Button extends Component {

  constructor() {
    super()
    this.state = {
      hover: false
    }
  }

  hoverIn = () => this.setState({ hover: true })

  hoverOut = () => this.setState({ hover: false })


  render() {
    const { onClick, label, link, target } = this.props;
    const { hover } = this.state || {};
    return link ? (
      <a
        href={link}
        target={target}
        className={`button ${hover ? 'hover' : ''}`}
        onMouseOver={this.hoverIn}
        onMouseOut={this.hoverOut}
        onClick={onClick}>
        {label}
      </a>
    ) : (
      <div
        className={`button ${hover ? 'hover' : ''}`}
        onMouseOver={this.hoverIn}
        onMouseOut={this.hoverOut}
        onClick={onClick}>
        {label}
      </div>
    );
  }
}
