import React, { Component } from 'react';
import { Motion, spring } from 'react-motion'
import { formatValues } from './core';

export default class Bar extends Component {
  formatValues = formatValues.bind(this)

  render() {
    const { bench, value: val, max, benchOn, reverse } = this.props;
    const value = isNaN(val) ? 0 : val;

    const benchValue = bench ? bench - value : 0;
    const barValue = benchValue < 0 ? value + benchValue : value;
    return (
      <Motion
        defaultStyle={{ barWidth: 0, benchWidth: 0 }}
        style={{
          barWidth: spring(Math.round(barValue / max * 100)),
          benchWidth: spring(Math.round(benchValue / max * 100))
        }}
      >
        {({ barWidth, benchWidth }) => (
          <div className='bar-section'>
            <div className='bar' style={{ width: `${barWidth}%` }}></div>
            {bench ? (
              <div className={`bench ${reverse ? 'reverse' : ''}`} style={{ width: `${Math.abs(benchWidth)}%` }}>
                {benchValue < 0 ? <div className='bar-line'/> : null}
                <div className={benchValue > 0 ? 'below-bench' : 'above-bench'}/>
                {benchValue > 0 ? <div className='bar-line'/> : null}
                {bench ? <span className='bench-label'>{benchValue && parseFloat(benchValue) !== 0 ? this.formatValues(benchValue, false, true) : 'no benchmark for this dimension'}</span> : ''}
              </div>
            ) : benchOn ? <span className='bench-label' style={{paddingLeft: '12px'}}> no benchmarks for this dimension</span> : ''}
          </div>
        )}
      </Motion>
    );
  }
}
