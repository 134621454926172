import React, { Component } from 'react';
import Graph from '../Graph';
import { connect } from 'react-redux';
import {
  graphSet,
  planSet,
  handleTabByPlan,
  relationshipSet,
  handleTabByRelationship,
  riskSet,
  handleTabByRisk,
  metricSet,
  planMetric,
  relationshipMetric,
  riskMetric,
} from './core'

class Membership extends Component {

  graphSet = graphSet.bind(this)
  planSet = planSet.bind(this)
  handleTabByPlan = handleTabByPlan.bind(this)
  relationshipSet = relationshipSet.bind(this)
  handleTabByRelationship = handleTabByRelationship.bind(this)
  riskSet = riskSet.bind(this)
  handleTabByRisk = handleTabByRisk.bind(this)
  metricSet = metricSet.bind(this)
  planMetric = planMetric.bind(this)
  relationshipMetric = relationshipMetric.bind(this)
  riskMetric = riskMetric.bind(this)

  render() {
    const {
      planTab = 0,
      relationshipTab = 0,
      riskTab = 0
    } = this.state || {};
    return (
      <div className='dash-page'>
        <h1>Membership</h1>
        <Graph
          label={'By Plan'}
          excludeInsights={true}
          tabs={['MEMBERS', 'RISK SCORE', 'AGE / SEX RISK SCORE']}
          neutral={[true, false, false]}
          format={['n', '.', '.'][planTab]}
          handleTab={this.handleTabByPlan}
          tab={planTab}
          metric={this.planMetric()}
          graphData={this.planSet()}
          allowBench={false}
          allowCompare={true}
        />

        <Graph
          label={'By Relationship'}
          tabs={['RELATIONSHIP', 'RISK SCORE', 'AGE / SEX RISK SCORE']}
          neutral={[true, false, false]}
          format={['n', '.', '.'][relationshipTab]}
          handleTab={this.handleTabByRelationship}
          tab={relationshipTab}
          metric={this.relationshipMetric()}
          graphData={this.relationshipSet()}
          allowBench={false}
          allowCompare={true}
        />

        <Graph
          label={'By Risk Score Band'}
          tabs={['RISK SCORE']}
          handleTab={this.handleTabByRisk}
          tab={riskTab}
          metric={this.riskMetric()}
          graphData={this.riskSet()}
          allowBench={false}
          allowCompare={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, {})(Membership)
