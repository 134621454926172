import {
  totalPlanPaid_plan,
  utilCount_plan,
  memberMonth_plan,
  paidPerUtil_plan,
  uniqueSet,
  filterUnique_dim1
} from '../../data/dataCore';

export function planSet(dimList){
  var tabs = [ 'perMember', 'perUtil', 'paid', 'visits' ];
  const { tab = 0 } = this.state || {}
  const {
    months,
    metricData,
    memberMonths
  } = this.props.data
  const {
    currentData,
    compareData,
    benchData,
    compareBenchData
  } = metricData;
  const {
    currentData: curMM,
    compareData: comMM
  } = memberMonths;
  // const all = cur.concat(com);
  const plans = uniqueSet(curMM, 'Plan_Description');
  const cur = currentData.filter(p => plans.indexOf(p.Plan_Description) > -1)
  const com = compareData.filter(p => plans.indexOf(p.Plan_Description) > -1)
  const bnc = benchData.filter(p => plans.indexOf(p.Plan_Description) > -1)
  const cbn = compareBenchData.filter(p => plans.indexOf(p.Plan_Description) > -1)

  var graphSet =  plans.sort().map((plan) => ({
    perMember: {
      name: plan,
      value: totalPlanPaid_plan(cur, plan) / memberMonth_plan(curMM, plan, months),
      compareValue: totalPlanPaid_plan(com, plan) / memberMonth_plan(comMM, plan, months),
      benchmark: totalPlanPaid_plan(bnc, plan) / memberMonth_plan(curMM, plan, months),
      compareBenchmark: totalPlanPaid_plan(cbn, plan) / memberMonth_plan(comMM, plan, months)
    },
    perUtil: {
      name: plan,
      value: paidPerUtil_plan(cur, plan),
      compareValue: paidPerUtil_plan(com, plan),
      benchmark: paidPerUtil_plan(bnc, plan),
      compareBenchmark: paidPerUtil_plan(cbn, plan)
    },
    paid: {
      name: plan,
      value: totalPlanPaid_plan(cur, plan),
      compareValue: totalPlanPaid_plan(com, plan),
      benchmark: totalPlanPaid_plan(bnc, plan),
      compareBenchmark: totalPlanPaid_plan(cbn, plan)
    },
    visits: {
      name: plan,
      value: utilCount_plan(cur, plan),
      compareValue: utilCount_plan(com, plan),
      benchmark: utilCount_plan(bnc, plan),
      compareBenchmark: utilCount_plan(cbn, plan)
    },
  })).map(d => d[tabs[tab]])

  return graphSet;
}

export function higherMetric() {
  const {
    tab = 0
  } = this.state || {};
  const {
    metricData: {
      currentData,
      compareData,
    },
    memberMonths: {
      current: curMM,
      currentData: curMMData,
      compare: comMM
    },
    months
  } = this.props.data;
  const plans = uniqueSet(curMMData, 'Plan_Description');
  const cur = currentData.filter(d => d.Dimension_1 === 'ER Utilization by Plan' && plans.indexOf(d.Plan_Description) > -1);
  const com = compareData.filter(d => d.Dimension_1 === 'ER Utilization by Plan' && plans.indexOf(d.Plan_Description) > -1);
  return [
    {
      title: 'PMPM Total',
      value: '$' + ((cur.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0) / curMM)).toFixed(2).toLocaleString(),
      compareValue: '$' + ((com.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0) / comMM)).toFixed(2).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total paid per util',
      value: '$' + Math.round((cur.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0) / cur.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      compareValue: '$' + Math.round((com.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0) / com.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'total paid',
      value: '$' + Math.round(cur.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0)).toLocaleString(),
      compareValue: '$' + Math.round(com.reduce((t, n) => t + (isNaN(n.Plan_Paid) ? 0 : n.Plan_Paid), 0)).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total visits',
      value: cur.reduce((t, n) => t + (isNaN(n.Util_Count) ? 0 : n.Util_Count), 0).toLocaleString(),
      compareValue: com.reduce((t, n) => t + (isNaN(n.Util_Count) ? 0 : n.Util_Count), 0).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    }
  ][tab]
}

export function visitSet() {
  const {
    metricData,
    toYear,
    toQuarter
  } = this.props.data
  const {
    currentData: cur,
    compareData: com,
    benchData: bnc,
    compareBenchData: cbn
  } = metricData;
  const all = cur.concat(com).concat(bnc).concat(cbn);
  const visits = filterUnique_dim1(all, 'Percent of Members by ER Visit Frequency', 'Dimension_2');
  const filterVisits = (arr, year) => arr.filter(d => (
    d.Dimension_1 === 'Percent of Members by ER Visit Frequency' &&
    d.Year === year &&
    d.Quarter === toQuarter
  )).reduce((t, n) => t + n.Member_Month_Count, 0)

  const filterERMM = (arr, dim2, year) => arr.filter(d => (
    d.Dimension_1 === 'Percent of Members by ER Visit Frequency' &&
    d.Dimension_2 === dim2 &&
    d.Year === year &&
    d.Quarter === toQuarter
  )).reduce((t, n) => t + n.Member_Month_Count, 0)

  var graphSet =  visits.sort().map((visit) => ({
    name: visit,
    reverse: visit === '1. No ER Visit in Past 12 Months' ? true : false,
    value: filterERMM(cur, visit, toYear) / filterVisits(cur, toYear) * 100,
    compareValue: filterERMM(com, visit, toYear - 1) / filterVisits(com, toYear - 1) * 100,
    benchmark: filterERMM(bnc, visit, toYear) / filterVisits(bnc, toYear) * 100,
    compareBenchmark: filterERMM(cbn, visit, toYear - 1) / filterVisits(cbn, toYear - 1) * 100,
  }))

  return graphSet;
}



export function severitySet() {
  const {
    metricData
  } = this.props.data
  const {
    currentData: cur,
    compareData: com,
    benchData: bnc,
    compareBenchData: cbn
  } = metricData;
  const all = cur.concat(com).concat(bnc).concat(cbn);
  const visits = filterUnique_dim1(all, 'Percent of ER Visits by Severity', 'Dimension_2');

  const filterSeverity = (arr) => arr.filter(d => (
    d.Dimension_1 === 'Percent of ER Visits by Severity'
  )).reduce((t, n) => t + n.Util_Count, 0)

  const filterERUtil = (arr, dim2) => arr.filter(d => (
    d.Dimension_1 === 'Percent of ER Visits by Severity' &&
    d.Dimension_2 === dim2
  )).reduce((t, n) => t + n.Util_Count, 0)

  var graphSet =  visits.sort().map((visit) => ({
    name: visit,
    value: filterERUtil(cur, visit) / filterSeverity(cur) * 100,
    compareValue: filterERUtil(com, visit) / filterSeverity(com) * 100,
    benchmark: filterERUtil(bnc, visit) / filterSeverity(bnc) * 100,
    compareBenchmark: filterERUtil(cbn, visit) / filterSeverity(cbn) * 100,
  }))

  return graphSet;
}

export function handleTab(i) {
  this.setState({
    tab: i
  })
}
