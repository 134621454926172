export const updateDateRange = (key, val) => ({
  type: 'UPDATE_DATE_RANGE',
  payload: {key, val}
})

export const toggleDate = () => {
  return ({
  type: 'TOGGLE_DATE',
})}

export const toggleBenchmark = () => ({
  type: 'TOGGLE_BENCHMARK'
})

export const toggleCompare = () => ({
  type: 'TOGGLE_COMPARE'
})

export const changeGroup = (group) => ({
  type: 'CHANGE_GROUP',
  payload: group
})

export const toggleSideNav = () => ({
  type: 'TOGGLE_SIDE_NAV'
})

export const closeSideNav = () => ({
  type: 'CLOSE_SIDE_NAV'
})

export const closeInsights = () => ({
  type: 'CLOSE_INSIGHTS',
})

export const toggleInsights = () => ({
  type: 'TOGGLE_INSIGHTS',
})

export const showDetails = (payload) => ({
  type: 'INSIGHT_DETAILS',
  payload,
})

export const closeDetails = () => ({
  type: 'CLOSE_INSIGHT_DETAILS',
})

export const closeDrawers = () => ({
  type: 'CLOSE_DRAWERS'
})
