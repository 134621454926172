import React, { Component } from 'react';
import Graph from '../Graph';
import { connect } from 'react-redux';
import {
  opiatePatients,
  opiateScripts
} from './core'

class Opioids extends Component {

  opiatePatients = opiatePatients.bind(this)
  opiateScripts = opiateScripts.bind(this)

  render() {
    return (
      <div className='dash-page'>
        <h1>Opioids</h1>
        <Graph
          label={'Opioid Prescribing Trends'}
          format={'%%'}
          yearly={true}
          tabs={['% of Total Eligible Members']}
          graphData={this.opiatePatients()}
          allowBench={true}
          allowCompare={true}
        />
        <Graph
          label={'Days Supplied in Initial Opioid Prescription'}
          format={'%%'}
          yearly={true}
          tabs={['% of Members with a New Opioid Prescription']}
          graphData={this.opiateScripts()}
          allowBench={true}
          allowCompare={true}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  data: state.data
})

export default connect(mapStateToProps, {})(Opioids)
