import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  toggleInsights,
  closeInsights,
  toggleDate,
  toggleSideNav
} from '../../actions/controls';
import Icon from '../Icon';
import DateInput from '../DateInput';
import logo from '../../assets/FCH_2019_Logo_COLOR_CORPORATE.jpg';
import insightIcon from '../../assets/icons/insight.svg';
import dateIcon from '../../assets/icons/date.svg';

class Header extends Component {

  toggleInsights = () => {
    const { insightToggle } = this.props;
    insightToggle();
  }

  toggleDate = () => {
    const { dateToggle, dateOpen, closeInsights } = this.props;
    if (!dateOpen) {
      closeInsights()
    }
    dateToggle();
  }

  // handleChange = ({target: {value}}) => {
  //   const { changeGroup } = this.props;
  //   changeGroup(value);
  // }

  toggleSideNav = () => {
    const { toggleSideNav } = this.props;
    toggleSideNav();
  }

  render() {
    const { /*group, groups,*/ insightsOpen, dateOpen, insights, dateRange } = this.props;

    return (
      <div className='header'>
        <div>
          <div className='side-nav-toggle' onClick={this.toggleSideNav}>
            <span>&#9776;</span>
          </div>
          <div className='logo'>
            <img src={logo} alt='First Choice'/>
          </div>
        </div>
        {/*<select onChange={this.handleChange}  defaultValue={group}>
          {groups.map((g, i) => (
            <option key={g} value={g}>{g}</option>
          ))}
        </select>*/}

        <div className='dropdowns'>

          <div className={`dropdown ${dateOpen ? 'open' : ''}`}>
            {dateOpen ? <DateInput /> : null}
            <div className='date' onClick={this.toggleDate}>
              <Icon
                icon={dateIcon}
                alt='date'
              />
              <span className='nav-title'>{dateRange}</span>
              <div className="dropdown-arrow" />
            </div>
          </div>

          <div className={`dropdown ${insightsOpen ? 'open' : ''}`}>
            <div className='insight' onClick={this.toggleInsights}>
              <Icon
                icon={insightIcon}
                alt='insight'
                badge={insights}
              />
              <span className='nav-title'>Insights</span>
              <div className="dropdown-arrow" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}


const mapStateToProps = state => {
  const {
    fromYear,
    fromQuarter,
    toYear,
    toQuarter,
    groups,
    group,
    insights,
    dateOpen
  } = state.data;
  return ({
    dateOpen,
    groups,
    group,
    insightsOpen: state.insight.open,
    insights: insights.length,
    dateRange: `Q${fromQuarter} ${fromYear} - Q${toQuarter} ${toYear}`
  })
}

const mapDispatchToProps = dispatch => ({
  insightToggle: () => dispatch(toggleInsights()),
  dateToggle: () => dispatch(toggleDate()),
  closeInsights: () => dispatch(closeInsights()),
  toggleSideNav: () => dispatch(toggleSideNav()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Header)
