import {
  totalPlanPaid_dim2,
  utilCount_dim2,
  paidPerUtil_dim2,
  uniqueSet
} from '../../data/dataCore';

export function graphData(dim1){
  var tabs = [ 'perMember', 'perUtil', 'paid', 'visits' ];
  const {
    mentalTab = 0,
    substanceTab = 0
  } = this.state || {};
  const tab = dim1 === 'Mental Health' ? mentalTab : substanceTab;
  const {
    metricData,
    memberMonths
  } = this.props.data
  const {
    currentData,
    compareData,
    benchData,
    compareBenchData,
  } = metricData;
  const cur = currentData.filter(m => m.Dimension_1 === dim1);
  const com = compareData.filter(m => m.Dimension_1 === dim1);
  const bnc = benchData.filter(m => m.Dimension_1 === dim1);
  const cbn = compareBenchData.filter(m => m.Dimension_1 === dim1);
  const {
    current: curMM,
    compare: comMM
  } = memberMonths;
  const all = cur.concat(com);
  const metric = uniqueSet(all, 'Dimension_2');

  var graphSet =  metric.sort().map((d1) => ({
    perMember: {
      name: d1,
      value: totalPlanPaid_dim2(cur, d1) / curMM,
      compareValue: totalPlanPaid_dim2(com, d1) / comMM,
      benchmark: totalPlanPaid_dim2(bnc, d1) / curMM,
      compareBenchmark: totalPlanPaid_dim2(cbn, d1) / comMM
    },
    perUtil: {
      name: d1,
      value: paidPerUtil_dim2(cur, d1),
      compareValue: paidPerUtil_dim2(com, d1),
      benchmark: paidPerUtil_dim2(bnc, d1),
      compareBenchmark: paidPerUtil_dim2(cbn, d1)
    },
    paid: {
      name: d1,
      value: totalPlanPaid_dim2(cur, d1),
      compareValue: totalPlanPaid_dim2(com, d1),
      benchmark: totalPlanPaid_dim2(bnc, d1),
      compareBenchmark: totalPlanPaid_dim2(cbn, d1)
    },
    visits: {
      name: d1,
      value: utilCount_dim2(cur, d1),
      compareValue: utilCount_dim2(com, d1),
      benchmark: utilCount_dim2(bnc, d1),
      compareBenchmark: utilCount_dim2(cbn, d1)
    },
  })).map(d => d[tabs[tab]])

  return graphSet;
}


export function higherMetric(dim1) {
  const {
    mentalTab = 0,
    substanceTab = 0
  } = this.state || {};
  const tab = dim1 === 'Mental Health' ? mentalTab : substanceTab;
  const {
    metricData: {
      currentData,
      compareData
    },
    memberMonths: {
      current: curMM,
      compare: comMM
    },
    months
  } = this.props.data;
  const cur = currentData.filter(m => m.Dimension_1 === dim1);
  const com = compareData.filter(m => m.Dimension_1 === dim1);
  return [
    {
      title: 'PMPM Total',
      value: '$' + ((cur.reduce((t, n) => t + n.Plan_Paid, 0) / curMM)).toFixed(2).toLocaleString(),
      compareValue: '$' + ((com.reduce((t, n) => t + n.Plan_Paid, 0) / comMM)).toFixed(2).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total paid per util',
      value: '$' + Math.round((cur.reduce((t, n) => t + n.Plan_Paid, 0) / cur.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      compareValue: '$' + Math.round((com.reduce((t, n) => t + n.Plan_Paid, 0) / com.reduce((t, n) => t + n.Util_Count, 0))).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'total paid',
      value: '$' + Math.round(cur.reduce((t, n) => t + n.Plan_Paid, 0)).toLocaleString(),
      compareValue: '$' + Math.round(com.reduce((t, n) => t + n.Plan_Paid, 0)).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    },
    {
      title: 'Total visits',
      value: cur.reduce((t, n) => t + n.Util_Count, 0).toLocaleString(),
      compareValue: com.reduce((t, n) => t + n.Util_Count, 0).toLocaleString(),
      subtitle: Math.round(curMM / months).toLocaleString() + ' Unique Members'
    }
  ][tab]
}

export function handleMentalTab(i) {
  this.setState({
    mentalTab: i
  })
}

export function handleSubstanceTab(i) {
  this.setState({
    substanceTab: i
  })
}
