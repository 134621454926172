// import claimsData from './Data.json';
// import benchmarkData from './Benchmark.json';

// export const groups = [...new Set(claimsData.map(d => d.FC_Group))];
//
// export const availableRange = [...new Set(claimsData.map(d => d.Year + (d.Quarter / 10)))].sort();

export const rangeData = (state) => {
  const {
    data,
    benchmark
  } = state.fullData;
  const userData = data.filter(d => d.FC_Group === state.group);
  const userBenchData = benchmark.filter(d => d.FC_Group === state.group || d.FC_Group === 'All');
  const startRange = state.fromYear + (state.fromQuarter / 10);
  const endRange = state.toYear + (state.toQuarter / 10);
  const currentData = userData.filter(d => {
    const period = d.Year + (d.Quarter / 10);
    return period >= startRange && period <= endRange
  })
  const compareData = userData.filter(d => {
    const period = d.Year + (d.Quarter / 10);
    return period >= startRange - 1 && period <= endRange - 1
  })
  const benchData = userBenchData.filter(d => {
    const period = d.Year + (d.Quarter / 10);
    return period >= startRange && period <= endRange
  })
  const compareBenchData = userBenchData.filter(d => {
    const period = d.Year + (d.Quarter / 10);
    return period >= startRange - 1 && period <= endRange - 1
  });

  return {
    currentData,
    compareData,
    benchData,
    compareBenchData
  };
}

export const monthsInRange = ({ fromYear, fromQuarter, toYear, toQuarter }) => (((toYear - fromYear) * 12) + (toQuarter - fromQuarter) * 3) + 3;

export const filterMetric = (obj, m) => {
  if (Array.isArray(obj)) {
    return obj.filter(d => d.Metric_File === m);
  } else {
    let filtered = {};
    Object.keys(obj).forEach(k => {
      filtered[k] = obj[k].filter(d => d.Metric_File === m);
    })
    return filtered;
  }
};

export const filterDim1 = (obj, m) => {
  if (Array.isArray(obj)) {
    return obj.filter(d => d.Dimension_1 === m);
  } else {
    let filtered = {};
    Object.keys(obj).forEach(k => {
      filtered[k] = obj[k].filter(d => d.Dimension_1 === m);
    })
    return filtered;
  }
};

export const totalMemberMonths = ({
  currentData,
  compareData
}) => ({
  currentData: filterMetric(currentData,  'Member Months'),
  compareData: filterMetric(compareData, 'Member Months'),
  current: filterMetric(currentData, 'Member Months')
    .map(d => isNaN(d.Member_Month_Count) ? 0 : d.Member_Month_Count).reduce((t, n) => t + n, 0),
  compare: filterMetric(compareData, 'Member Months')
    .map(d => isNaN(d.Member_Month_Count) ? 0 : d.Member_Month_Count).reduce((t, n) => t + n, 0)
});

export const generateInsights = ({currentData, benchData}, curMM, MM, months, quarter, year) => {
  const eThresh = 6;
  const bThresh = 8;
  const positive = '#06c774';
  const negative = '#f24865';
  const warning = '#ff9d40';
  const erCur = filterDim1(currentData, 'ER Utilization by Plan');
  const erBnc = filterDim1(benchData, 'ER Utilization by Plan');
  const erPlans = uniqueSet(curMM, 'Plan_Description');
  const erInsights = erPlans.map(p => {
    const cv = totalPlanPaid_plan(erCur, p) / memberMonth_plan(curMM, p, months);
    const bv = totalPlanPaid_plan(erBnc, p) / memberMonth_plan(curMM, p, months);
    const ppu = paidPerUtil_plan(erCur, p);
    const bppu = ppu - paidPerUtil_plan(erBnc, p);
    const vis = utilCount_plan(erCur, p);
    const bvis = vis - utilCount_plan(erBnc, p);

    const value = cv - bv;
    return ({
      color: value < 0 ? positive : value >= eThresh ? negative : warning,
      metric: 'Emergency Room',
      dimension: 'ER Utilization by Plan',
      dim_description: p,
      link: '/emergency-room#er_utilization_by_plan',
      tab: 'PMPM',
      value,
      format: '$' + Math.abs(value).toFixed(2),
      detailRows: [
        {
          metric: 'PMPM',
          value: '$' + cv.toFixed(2),
          bench: (value < 0 ? '- ' : '+ ') + '$' + Math.abs(value).toFixed(2)
        },
        {
          metric: 'Paid / Utilization',
          value: '$' + ppu.toFixed(2),
          bench: (bppu < 0 ? '- ' : '+ ') + '$' + Math.abs(bppu).toFixed(2)
        },
        {
          metric: 'Visits',
          value: Math.round(vis),
          bench: (bppu < 0 ? '- ' : '+ ') + Math.round(Math.abs(bvis))
        }
      ]
    })
  });
  const menCur = filterDim1(currentData, 'Mental Health');
  const menBnc = filterDim1(benchData, 'Mental Health');
  const menPlans = uniqueSet(menCur, 'Dimension_2');
  const menInsights = menPlans.map(p => {
    const cv = totalPlanPaid_dim2(menCur, p) / MM;
    const bv = totalPlanPaid_dim2(menBnc, p) / MM;
    const ppu = paidPerUtil_dim2(menCur, p);
    const bppu = ppu - paidPerUtil_dim2(menBnc, p);
    const vis = utilCount_dim2(menCur, p);
    const bvis = vis - utilCount_dim2(menBnc, p);
    const value = cv - bv;
    return ({
      color: value < -8 ? positive : value >= bThresh ? negative : warning,
      metric: 'Behavioral Health',
      dimension: 'Mental Health',
      dim_description: p,
      link: '/behavioral#mental_health',
      tab: 'PMPM',
      value,
      format: '$' + Math.abs(value).toFixed(2),
      detailRows: [
        {
          metric: 'PMPM',
          value: '$' + cv.toFixed(2),
          bench: (value < 0 ? '- ' : '+ ') + '$' + Math.abs(value).toFixed(2)
        },
        {
          metric: 'Paid / Utilization',
          value: '$' + ppu.toFixed(2),
          bench: (bppu < 0 ? '- ' : '+ ') + '$' + Math.abs(bppu).toFixed(2)
        },
        {
          metric: 'Visits',
          value: Math.round(vis),
          bench: (bppu < 0 ? '- ' : '+ ') + Math.round(Math.abs(bvis))
        }
      ]
    })
  });
  const subCur = filterDim1(currentData, 'Substance Abuse');
  const subBnc = filterDim1(benchData, 'Substance Abuse');
  const subPlans = uniqueSet(subCur, 'Dimension_2');
  const subInsights = subPlans.map(p => {
    const cv = totalPlanPaid_dim2(subCur, p) / MM;
    const bv = totalPlanPaid_dim2(subBnc, p) / MM;
    const ppu = paidPerUtil_dim2(subCur, p);
    const bppu = ppu - paidPerUtil_dim2(subBnc, p);
    const vis = utilCount_dim2(subCur, p);
    const bvis = vis - utilCount_dim2(subBnc, p);
    const value = cv - bv;
    return ({
      color: value < -8 ? positive : value >= bThresh ? negative : warning,
      metric: 'Behavioral Health',
      dimension: 'Substance Abuse',
      dim_description: p,
      link: '/behavioral#substance_abuse',
      tab: 'PMPM',
      value,
      format: '$' + Math.abs(value).toFixed(2),
      detailRows: [
        {
          metric: 'PMPM',
          value: '$' + cv.toFixed(2),
          bench: (value < 0 ? '- ' : '+ ') + '$' + Math.abs(value).toFixed(2)
        },
        {
          metric: 'Paid / Utilization',
          value: '$' + ppu.toFixed(2),
          bench: (bppu < 0 ? '- ' : '+ ') + '$' + Math.abs(bppu).toFixed(2)
        },
        {
          metric: 'Visits',
          value: Math.round(vis),
          bench: (bppu < 0 ? '- ' : '+ ') + Math.round(Math.abs(bvis))
        }
      ]
    })
  });

  const opCur = filterOpiate(currentData, year, quarter).filter(d => d.Dimension_1 === 'Percent of Membership');
  const opBnc = filterOpiate(benchData, year, quarter).filter(d => d.Dimension_1 === 'Percent of Membership');
  const opCurD = filterOpiate(currentData, year, quarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const opBncD = filterOpiate(benchData, year, quarter).filter(d => d.Dimension_1 === 'Days Supplied - Initial Opioid Prescription');
  const oThresh = 4;
  const cronic = '2. Percent of Members Prescribed Opioids Who Are Considered Chronic Opioid Users';
  const sedative = '3. Percent of Members Prescribed Opioids Who Are Also Prescribed Sedatives';
  const more13 = '4. 13 or More Days';
  const scv = (filterOpiateDim2(opCur, sedative)) / filterOpiateDim2(opCur, cronic) * 100;
  const sbv = (filterOpiateDim2(opBnc, sedative)) / filterOpiateDim2(opBnc, cronic) * 100;
  const sValue = scv - sbv;
  // const getDimension = (arr, dim2) => arr.filter(a => a.Dimension_2 === dim2);
  const opiateMM = (arr) => arr.reduce((t, n) => t + n.Member_Month_Count, 0)
  const ocv = (filterOpiateDim2(opCurD, more13)) / opiateMM(opCurD) * 100;
  const obv = (filterOpiateDim2(opBncD, more13)) / opiateMM(opBncD) * 100;
  const oValue = ocv - obv;

  const opInsights = [{
    color: sValue <= -oThresh ? positive : sValue >= oThresh ? negative : warning,
    metric: 'Opiods',
    dimension: 'Prescribing Trends',
    dim_description: '3. Percent of Members Prescribed Opioids Who Are Also Prescribed Sedatives',
    link: '/opioids#opioid_prescribing_trends',
    tab: '% of total eligible members',
    value: sValue,
    format: Math.abs(sValue).toFixed(1) + '%',
    detailRows: [{
      metric: '% of total eligible members',
      value: scv.toFixed(1) + '%',
      bench: (sValue < 0 ? '- ' : '+ ') + Math.abs(sValue).toFixed(1) + '%'
    }]
  }, {
    color: oValue <= -oThresh ? positive : oValue >= oThresh ? negative : warning,
    metric: 'Opiods',
    dimension: 'Days Supplied in Initial Opioid Prescription',
    dim_description: '4. 13 or More Days',
    link: '/opioids#days_supplied_in_initial_opioid_prescription',
    tab: '% of members with new opioid prescription',
    value: oValue,
    format: Math.abs(oValue).toFixed(1) + '%',
    detailRows: [{
      metric: '% of members with new opioid prescription',
      value: ocv.toFixed(1) + '%',
      bench: (oValue < 0 ? '- ' : '+ ') + Math.abs(oValue).toFixed(1) + '%'
    }]
  }];
  console.log({opInsights});

  return erInsights.concat(menInsights).concat(subInsights).concat(opInsights).filter(ins => ins.color !== warning);

}

export const reduceSet = (arr, rd) => arr.reduce((t, n) => t + (n[rd] === null ? 0 : n[rd]), 0);

export const filterReduceSet = (arr, fv, fk, rd) => reduceSet(arr.filter(m => m[fk] === fv), rd);

export const totalPlanPaid_dim1 = (arr, dim) =>  filterReduceSet(arr, dim, 'Dimension_1', 'Plan_Paid');

export const utilCount_dim1 = (arr, dim) => filterReduceSet(arr, dim, 'Dimension_1', 'Util_Count');

export const paidPerUtil_dim1 = (arr, dim) => totalPlanPaid_dim1(arr, dim) / utilCount_dim1(arr, dim);

export const totalPlanPaid_dim2 = (arr, dim) => filterReduceSet(arr, dim, 'Dimension_2', 'Plan_Paid');

export const utilCount_dim2 = (arr, dim) => filterReduceSet(arr, dim, 'Dimension_2', 'Util_Count');

export const paidPerUtil_dim2 = (arr, dim) => totalPlanPaid_dim2(arr, dim) / utilCount_dim2(arr, dim);

export const memberMonth_dim2 = (arr, dim) => filterReduceSet(arr, dim, 'Dimension_2', 'Member_Month_Count');

export const totalPlanPaid_plan = (arr, dim) => filterReduceSet(arr, dim, 'Plan_Description', 'Plan_Paid');

export const utilCount_plan = (arr, dim) => filterReduceSet(arr, dim, 'Plan_Description', 'Util_Count');

export const memberMonth_plan = (arr, dim) => filterReduceSet(arr, dim, 'Plan_Description', 'Member_Month_Count') || 1;

export const paidPerUtil_plan = (arr, dim) => totalPlanPaid_plan(arr, dim) / utilCount_plan(arr, dim);

export const uniqueSet = (arr, key) => arr.map(o => o[key]).filter((v, i, s) => s.indexOf(v) === i).filter(n => n !== null);

export const filterUnique_dim1 = (arr, dim1, key) => arr.filter(o => o.Dimension_1 === dim1).map(o => o[key]).filter((v, i, s) => s.indexOf(v) === i).filter(n => n !== null);

export const filterOpiate = (arr, y, q) => arr.filter(d => (
  d.Metric_File === "Opiate Usage" &&
  d.Year === y &&
  d.Quarter === q
));

export const filterOpiateDim2 = (arr, dim) => {
  const filtered = arr.filter(d => d.Dimension_2 === dim);
  return filtered.length ? filtered[0].Member_Month_Count : 0
}
