import React, { Component } from 'react';
import { connect } from 'react-redux';
import { showDetails } from '../../actions/controls';

class InsightTip extends Component {
  constructor() {
    super();
    this.state = {
      hover: false
    }
  }

  showDetails = () => {
    const {
      insight,
      showDetails
    } = this.props;
    showDetails(insight)
  }

  render() {
    const { insight } = this.props;
    const {
      color,
      dim_description,
      tab,
      value,
      format,
    } = insight || {};

    const { hover } = this.state;

    return insight ? (
      <div
        onMouseOver={() => this.setState({hover: true})}
        onMouseOut={() => this.setState({hover: false})}
        onClick={this.showDetails}
        className={`insight-tip ${hover ? 'hover' : ''}`}
      >
        <div className='insight-dot' style={{ background: color }}></div>
        <div className='insight-tooltip'>
          {dim_description} {tab} cost is {value >= 0 ? 'above' : 'below'} benchmark value by {format}
        </div>
      </div>
    ) : <div/>;
  }
}

const mapStateToProps = state => {
  const {
    fromQuarter,
    fromYear,
    toQuarter,
    toYear,
  } = state.data;
  return ({
    range: `Q${fromQuarter}, ${fromYear} - Q${toQuarter}, ${toYear}`,
    compareRange: `Q${fromQuarter}, ${fromYear - 1} - Q${toQuarter}, ${toYear - 1}`,
    insights: state.data.insights
  })
}

const mapDispatchToProps = dispatch => ({
  showDetails: (obj) => dispatch(showDetails(obj)),
})

export default connect(mapStateToProps, mapDispatchToProps)(InsightTip)
