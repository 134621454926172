import React, { Component } from 'react';
import { connect } from 'react-redux';
import { updateDateRange, toggleDate } from '../../actions/controls';
// import { availableRange } from '../../data/dataCore';
import Icon from '../Icon';
import infoIcon from '../../assets/icons/info.svg';
import close from '../../assets/icons/close.svg';

const parseDate = (date) => ({
  y: Math.floor(date),
  q: Math.round((date - Math.floor(date)) * 10)
});

class DateInput extends Component {

  handleChange(key, { target: { value } }) {
    this.props.updateDateRange(key, parseInt(value))
  }

  dateDisabled = (p, d) => {
    const {
      fromYear,
      fromQuarter,
      toYear,
      toQuarter,
      availableRange
    } = this.props;
    if (
      availableRange.indexOf(d) < 0 ||
      (p === 'to' && d < (fromYear + (fromQuarter / 10))) ||
      (p === 'from' && d > (toYear + (toQuarter / 10)))
    ) {
      return true;
    }
    return false;
  }

  render() {
    const {
      fromYear,
      fromQuarter,
      toYear,
      toQuarter,
      updateDateRange,
      dateToggle,
      months,
      availableRange
    } = this.props;
    
    const av = {
      f: parseDate(availableRange[0]),
      t: parseDate(availableRange[availableRange.length - 1]),
    }
    const availableYears = [ ...new Set(availableRange.map(y => Math.floor(y))) ]
    const availableQuarters = [1, 2, 3, 4];
    const info = `Your data is available from Q${av.f.q} ${av.f.y} till Q${av.t.q} ${av.t.y}. Claims data typically takes 90 days to accurately represent your population.`
    return (
      <div className='date-input'>
        <div
          className='overlay'
          onClick={dateToggle}
        />
        <div className='close-button' onClick={dateToggle}>
          <Icon
            icon={close}
            alt='x'
          />
        </div>
        <h3>Date Range <Icon icon={infoIcon} tooltip={info}/></h3>
        <div className='date-range-labels'>
          <div className='date-range-label'>
            From
          </div>
          <div className='date-range-label'>
            To
          </div>
        </div>
        <div className='date-radios'>
          <div className='date-selection date-from'>
            <div className='radioGroup'>
              {availableQuarters.map(q => (
                <label key={`fromQuarter_${q}`}>
                  <input
                    type="radio"
                    checked={q === fromQuarter}
                    disabled={this.dateDisabled('from', fromYear + q / 10)}
                    value={q}
                    name={'fromQuarter'}
                    onChange={() => updateDateRange('fromQuarter', q)}
                   />
                  <span>Q{q}</span>
                </label>
              ))}
            </div>
            <div className='radioGroup'>
              {availableYears.map(y => (
                <label key={`fromYear_${y}`}>
                  <input
                    type="radio"
                    checked={y === fromYear}
                    disabled={this.dateDisabled('from', y + fromQuarter / 10)}
                    value={y}
                    name={'fromYear'}
                    onChange={() => updateDateRange('fromYear', y)}
                   />
                  <span>{y}</span>
                </label>
              ))}
            </div>
          </div>
          <div className='date-border' />
          <div className='date-selection date-to'>
            <div className='radioGroup'>
              {availableQuarters.map(q => (
                <label key={`toQuarter_${q}`}>
                  <input
                    type="radio"
                    checked={q === toQuarter}
                    disabled={this.dateDisabled('to', toYear + q / 10)}
                    value={q}
                    name={'toQuarter'}
                    onChange={() => updateDateRange('toQuarter', q)}
                   />
                  <span>Q{q}</span>
                </label>
              ))}
            </div>
            <div className='radioGroup'>
              {availableYears.map(y => (
                <label key={`toYear_${y}`}>
                  <input
                    type="radio"
                    checked={y === toYear}
                    disabled={this.dateDisabled('to', y + toQuarter / 10)}
                    value={y}
                    name={'toYear'}
                    onChange={() => updateDateRange('toYear', y)}
                   />
                  <span>{y}</span>
                </label>
              ))}
            </div>
          </div>
          <div className='date-fade' />
        </div>
        {months !== 12 ? (
          <div className='date-input-footer'>
            <div className='date-alert'>
              Some graphs require 4 quarters of data to display. 4 quarters including the selected "to" date will be used.
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    fromYear,
    fromQuarter,
    toYear,
    toQuarter,
    months,
    availableRange
  } = state.data;
  return ({
    fromYear,
    fromQuarter,
    toYear,
    toQuarter,
    months,
    availableRange,
    dateRange: `Q${fromQuarter} ${fromYear} - Q${toQuarter} ${toYear}`
  })
}

const mapDispatchToProps = dispatch => ({
  updateDateRange: (key, val) => dispatch(updateDateRange(key, val)),
  dateToggle: () => dispatch(toggleDate())
})

export default connect(mapStateToProps, mapDispatchToProps)(DateInput)
