import appImage from '../assets/fchn.png';

export const programs = [
  {
    category: 'behavioral',
    title: 'EAP – Employee Assistance Program',
    description: 'Our FCH EAP program provides confidential support for emotional and behavioral work-life challenges encountered by employees and their family members.',
    image: appImage,
    link: '/programs/EAP-Sales-flyer-02142019.pdf'
  },
  {
    category: 'behavioral',
    title: 'Behavioral Health Coordinated Care',
    description: 'The BHCC program is designed to help respond to the growing need for mental health resources for employees as well as alleviate provider access issues.',
    image: appImage,
    link: '/programs/behavioral-health-flyer-draft2.pdf'
  },
  {
    category: 'behavioral',
    title: 'Lifestyle Coaching Program',
    description: 'The dynamic and interactive Lifestyle Coaching programs provide qualified members with telephonic and online coaching. ',
    image: appImage,
    link: '/programs/Nurse-Health-Coaching_01252019.pdf'
  },
  {
    category: 'emergency-room',
    title: 'ER Utilization Management',
    description: 'Our Utilization Management team actively collaborates with community providers to ensure patient safety, optimize the health of our members, and enable cost-saving strategies wherever possible.',
    image: appImage,
    link: '/programs/Util-Management-Flyer-02142019.pdf'
  },
  {
    category: 'emergency-room',
    title: 'Case Management',
    description: 'Our Case Managers work closely with benefit managers, program administrators, families, and providers to coordinate delivery of high quality, medically necessary services.',
    image: appImage,
    link: '/programs/Case_Management-08272018.pdf'
  },
  {
    category: 'opiate',
    title: 'Opioid Management Program - COPE',
    description: 'FCH offers data analysis and outreach on an ongoing basis, providing much-needed support and education to our clients and their workforces who are struggling with chronic opioid misuse and abuse.',
    image: appImage,
    link: '/programs/COPE-Program-Flyer-07102019.pdf'
  },
  {
    category: 'opiate',
    title: 'Case Management',
    description: 'Our Case Managers work closely with benefit managers, program administrators, families, and providers to coordinate delivery of high quality, medically necessary services.',
    image: appImage,
    link: '/programs/Case_Management-08272018.pdf'
  }
]
