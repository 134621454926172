import React, { Component } from 'react';
import { connect } from 'react-redux';
import logo from '../../assets/FCH_2019_Logo_COLOR_CORPORATE.jpg';
import { closeSideNav } from '../../actions/controls';
import NavItem from '../NavItem';

const routes = [
  {
    title: 'Membership',
    url: '/membership'
  },
  {
    title: 'Claim Costs',
    url: '/claim-cost'
  },
  {
    title: 'Emergency Room',
    url: '/emergency-room'
  },
  {
    title: 'Behavioral Health',
    url: '/behavioral'
  },
  {
    title: 'Opioids',
    url: '/opioids'
  },
]
class NavBar extends Component {

  closeSideNav = () => {
    const { closeSideNav } = this.props;
    closeSideNav();
  }

  render() {
    const { open, group } = this.props;

    return (
      <div className={ `nav-container  ${open && 'open'}` }>
        <div className='nav-bar'  onClick={this.closeSideNav}>
          <div className='side-nav-logo'>
            <div className='side-nav-toggle'>
              &#10005;
            </div>
            <div className='logo'>
              <img src={logo} alt='First Choice'/>
            </div>
          </div>
          {routes.map((data, i) => <NavItem {...data} key={`item${i}`} />)}
          <div className="sign-out">
            <h4 className='username'>{group}</h4>
            <div className={'nav-item'} onClick={() => localStorage.removeItem('token')}>
              <a href='/'>
                <span className='nav-title'>sign out</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = state => ({
  open: state.dashboard.sideNav,
  group: state.data.group

})

const mapDispatchToProps = dispatch => ({
  closeSideNav: () => dispatch(closeSideNav())
})

export default connect(mapStateToProps, mapDispatchToProps)(NavBar)
