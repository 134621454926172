const reduceAverage = (arr, rd) => arr.reduce((t, n) => t + (n[rd] * n.Member_Month_Count), 0) / arr.reduce((t, n) => t + (n.Member_Month_Count), 0);

const filterAverage = (arr, fv, fk, rd) => reduceAverage(arr.filter(m => m[fk] === fv && m[rd]), rd);

const memberMonth = (arr, dim, filt, months) => arr.filter(m => m[filt] === dim && m.Member_Month_Count).reduce((t, n) => t + (n.Member_Month_Count), 0) / months;

const risk = (arr, dim, filt) => filterAverage(arr, dim, filt, 'AvgRiskScore');

const ageRisk = (arr, dim, filt) => filterAverage(arr, dim, filt, 'AgeSexMDRisk');

const uniqueSet = (arr, key) => arr.map(o => o[key]).filter((v, i, s) => s.indexOf(v) === i);

const localString = n => isNaN(n) ? 'No Data' : n.toLocaleString()

export function graphSet(filterBy, tab) {
  const keys = ['member', 'risk', 'ageRisk'];
  const {
    metricData: {
      currentData: cur,
      compareData: com,
      benchData: bnc,
      compareBenchData: cbn,
    },
    months
  } = this.props.data;
  const all = cur.concat(com).concat(bnc).concat(cbn);
  const plans = uniqueSet(all, filterBy);
  return plans.sort().map(plan => ({
    member: {
      name: plan,
      value: memberMonth(cur, plan, filterBy, months),
      compareValue: memberMonth(com, plan, filterBy, months),
      benchmark: memberMonth(bnc, plan, filterBy, months),
      compareBenchmark: memberMonth(cbn, plan, filterBy, months)
    },
    risk: {
      name: plan,
      value: risk(cur, plan, filterBy, months),
      compareValue: risk(com, plan, filterBy, months),
      benchmark: risk(bnc, plan, filterBy, months),
      compareBenchmark: risk(cbn, plan, filterBy, months)
    },
    ageRisk: {
      name: plan,
      value: ageRisk(cur, plan, filterBy, months),
      compareValue: ageRisk(com, plan, filterBy, months),
      benchmark: ageRisk(bnc, plan, filterBy, months),
      compareBenchmark: ageRisk(cbn, plan, filterBy, months)
    }
  })).map(d => d[keys[tab]])
}

export function metricSet(filterBy, tab) {
  const {
    metricData: {
      currentData: cur,
      compareData: com,
      benchData: bnc,
      compareBenchData: cbn,
    },
    months
  } = this.props.data;
  const all = cur.concat(com).concat(bnc).concat(cbn);
  const plans = uniqueSet(all, filterBy);
  const metrics = plans.map(plan => ({
    member: {
      name: plan,
      value: memberMonth(cur, plan, filterBy, months),
      compareValue: memberMonth(com, plan, filterBy, months),
      benchmark: memberMonth(bnc, plan, filterBy, months),
      compareBenchmark: memberMonth(cbn, plan, filterBy, months)
    },
    risk: {
      name: plan,
      value: risk(cur, plan, filterBy, months),
      compareValue: risk(com, plan, filterBy, months),
      benchmark: risk(bnc, plan, filterBy, months),
      compareBenchmark: risk(cbn, plan, filterBy, months)
    },
    ageRisk: {
      name: plan,
      value: ageRisk(cur, plan, filterBy, months),
      compareValue: ageRisk(com, plan, filterBy, months),
      benchmark: ageRisk(bnc, plan, filterBy, months),
      compareBenchmark: ageRisk(cbn, plan, filterBy, months)
    }
  }))
  return [
    {
      title: 'Monthly Average',
      value: localString(Math.round(metrics.map(m => m.member.value).reduce((t, n) => t + n, 0))),
      compareValue: localString(Math.round(metrics.map(m => m.member.compareValue).reduce((t, n) => t + n, 0)))
    },
    {
      title: 'Risk Average',
      value: localString((metrics.map(m => m.risk.value).reduce((t, n) => t + n, 0) / metrics.length).toFixed(2)),
      compareValue: localString((metrics.map(m => m.risk.compareValue).reduce((t, n) => t + n, 0) / metrics.length).toFixed(2))
    },
    {
      title: 'Risk Average',
      value: localString((metrics.map(m => m.ageRisk.value).reduce((t, n) => t + n, 0) / metrics.length).toFixed(2)),
      compareValue: localString((metrics.map(m => m.ageRisk.compareValue).reduce((t, n) => t + n, 0) / metrics.length).toFixed(2))
    },
  ][tab]
}

export function planSet() {
  const { planTab = 0 } = this.state || {};
  return this.graphSet('Plan_Description', planTab)
}

export function relationshipSet() {
  const { relationshipTab = 0 } = this.state || {};
  return this.graphSet('Dimension_1', relationshipTab)
}

export function riskSet() {
  const set = this.graphSet('Dimension_2', 0);

  return set.filter(s => s.name).map(s => Object.assign(s, { value: parseInt(s.value), compareValue: parseInt(s.compareValue) }))
}

export function planMetric() {
  const { planTab = 0 } = this.state || {};
  return this.metricSet('Plan_Description', planTab)
}

export function relationshipMetric() {
  const { relationshipTab = 0 } = this.state || {};
  return this.metricSet('Dimension_1', relationshipTab)
}

export function riskMetric() {
  return this.metricSet('Dimension_2', 0);
}

export function handleTabByPlan(i) {
  this.setState({
    planTab: i
  })
}

export function handleTabByRelationship(i) {
  this.setState({
    relationshipTab: i
  })
}

export function handleTabByRisk(i) {
  this.setState({
    riskTab: 0
  })
}
