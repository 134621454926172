import React, { Component } from 'react';
import Ticks from './ticks'
import Row from './row'
import { renderTicks } from './core';

export default class BarGraph extends Component {

  render() {
    const { data, compare, bench, format, yearly, excludeInsights, neutral } = this.props;

    const ticks = renderTicks(data, compare, bench, format);

    return (
      <div className='bar-graph'>
        {data.length ? (
          <div className='graph'>
          <Ticks data={ticks} format={format}/>
            {data.map((bar, i) => (
              <Row
                data={bar}
                neutral={neutral}
                index={data.length > 1 ? i : 0}
                key={`section_${i}`}
                compare={compare}
                format={format}
                yearly={yearly}
                bench={bench}
                excludeInsights={excludeInsights}
                max={ticks[ticks.length - 1]}
              />
            ))}
          </div>
        ) : <h2>No data available</h2>}
      </div>
    );
  }
}
