import React, { Component } from 'react';
import Slider from "react-slick";
import Slide from './item';
import Icon from '../Icon';
import next from '../../assets/icons/next.svg';
import prev from '../../assets/icons/prev.svg';
import { connect } from 'react-redux';

function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", margin: '18px' }}
      onClick={onClick}
    >
      <Icon icon={next}/>
    </div>
  );
}

function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", margin: '18px'}}
      onClick={onClick}
    >
      <Icon icon={prev}/>
    </div>
  );
}

class Programs extends Component {
  render() {
    const { data } = this.props;
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      nextArrow: <NextArrow/>,
      prevArrow: <PrevArrow/>
    };
    return data.length ? (
      <div className='programs'>
        <h3>
          suggested programs
        </h3>
        <div className='carousel-container' >
          <Slider {...settings}>
            {data.map((c, i) => (<Slide  key={`slide_${i}`}{...c} />))}
          </Slider>
        </div>
      </div>
    ) : <div/>;
  }
}

const mapStateToProps = state => ({
  data: state.program.programs
})

export default connect(mapStateToProps, {})(Programs)
