// import claimsData from '../data/Data.json';
// import benchmarkData from '../data/Benchmark.json';
import { pageConfig } from '../config/dashboard';

import {
  monthsInRange,
  filterMetric,
  totalMemberMonths,
  rangeData,
  // groups,
  generateInsights,
} from '../data/dataCore';

const INITIAL_STATE = {
  group: '',
  // groups,
  dateOpen: false,
  fromYear: 2018,
  fromQuarter: 1,
  toYear: 2018,
  toQuarter: 4,
  months: 12,
  fullData: {
    data: [],
    benchmark: []
  },
  availableRange: [],
  memberMonths: {
    current: 0,
    compare: 0,
    currentData: [],
    compareData:[]
  },
  rangeData: {
    currentData: [],
    compareData: [],
    benchData: [],
    compareBenchData: []
  },
  metricData: {
    currentData: [],
    compareData: [],
    benchData: [],
    compareBenchData: []
  },
  insights: []
};

const getMetric = (url) => {
  var config = pageConfig.filter(c => c.url === url);
  if (config.length) return config[0].metric;
  return '';
}

export default (state = INITIAL_STATE, action) => {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case 'TOGGLE_DATE':
      newState.dateOpen = !state.dateOpen;
      break
    case 'LOAD_DATA':
      newState.fullData = action.payload;
      newState.availableRange = [...new Set(action.payload.data.map(d => d.Year + (d.Quarter / 10)))].sort();
      newState.group = action.payload.data[0].FC_Group;
      newState.rangeData = rangeData(newState);
      newState.metricData = filterMetric(newState.rangeData, newState.metric);
      newState.months = monthsInRange(newState);
      newState.memberMonths = totalMemberMonths(newState.rangeData, newState.months)
      newState.insights = generateInsights(newState.rangeData, newState.memberMonths.currentData, newState.memberMonths.current, newState.months, newState.toQuarter, newState.toYear);
      break
    case 'CLOSE_DRAWERS':
      newState.dateOpen = false;
      break;
    case '@@router/LOCATION_CHANGE':
      newState.metric = getMetric(action.payload.location.pathname)
      newState.rangeData = rangeData(newState)
      newState.metricData = filterMetric(newState.rangeData, newState.metric)
      newState.memberMonths = totalMemberMonths(newState.rangeData, newState.months)
      newState.insights = generateInsights(newState.rangeData, newState.memberMonths.currentData, newState.memberMonths.current, newState.months, newState.toQuarter, newState.toYear);
      break
    case 'UPDATE_DATE_RANGE':
      const { key, val } = action.payload;
      newState[key] = val;
      newState.rangeData = rangeData(newState)
      newState.metricData = filterMetric(newState.rangeData, newState.metric)
      newState.months = monthsInRange(newState)
      newState.memberMonths = totalMemberMonths(newState.rangeData, newState.months)
      newState.insights = generateInsights(newState.rangeData, newState.memberMonths.currentData, newState.memberMonths.current, newState.months, newState.toQuarter, newState.toYear);
      break
    case 'CHANGE_GROUP':
      newState.group = action.payload;
      newState.rangeData = rangeData(newState)
      newState.metricData = filterMetric(newState.rangeData, newState.metric)
      newState.memberMonths = totalMemberMonths(newState.rangeData, newState.months)
      newState.insights = generateInsights(newState.rangeData, newState.memberMonths.currentData, newState.memberMonths.current, newState.months, newState.toQuarter, newState.toYear);
      break
    default:
      return newState;
  }

  return newState;
};
