import React, { Component } from 'react';
import { connect } from 'react-redux';
import Icon from '../Icon';
import infoIcon from '../../assets/icons/info.svg';
import Metric from './metric';
import Toggle from '../Toggle';
import Tabs from '../Tabs';
import Chart from './chart';

class Graph extends Component {
  constructor(props) {
    super(props)
    this.state = {
      compare: false,
      bench: !!props.hash
    }
  }

  componentWillReceiveProps(nextProps) {
    const { hash } = this.props;
    const { bench = false } = this.state || {}

    if (
      nextProps.hash &&
      hash !== nextProps.hash &&
      !bench &&
      window.location.href.indexOf('#') > -1
    ) {
      this.setState({ bench: true })
    }
  }

  render() {
    const {
      tabs,
      tab,
      label,
      info,
      handleTab,
      graphData,
      allowBench,
      allowCompare,
      format,
      metric = {},
      yearly,
      endDate,
      months,
      excludeInsights,
      neutral
    } = this.props;
    const { compare, bench } = this.state;
    
    return (
      <div id={label.toLowerCase().replace(/ /g, '_')} className='graph-set'>
        <div className='graph-header'>
          <h3>{label} {info && <Icon icon={infoIcon} tooltip={info}/>}</h3>
          <div className='toggle-inputs'>
            {allowCompare && <Toggle value={compare} handleChange={() => this.setState({ compare: !compare })} label='Compare to prior year'/>}
            {allowBench && <Toggle value={bench} handleChange={() => this.setState({ bench: !bench })} label='Benchmarking'/>}
          </div>
        </div>
        <Tabs tab={tab} labels={tabs} handleChange={handleTab} />
        {yearly && months !== 12 ? (
          <div className='graph-alert'>This graph requires 12 months of data to display. We’ve adjusted the range to one full year from {endDate}. </div>
        ) : <div/>}

        {graphData.length && !isNaN(graphData.reduce((t, n) => t + n.value, 0)) ? (
          <div>
            <Metric metric={metric} compare={compare} neutral={neutral && neutral[tab]}/>
            <Chart
              excludeInsights={excludeInsights}
              neutral={neutral && neutral[tab]}
              format={format}
              compare={compare}
              bench={bench}
              yearly={yearly}
              data={graphData}/>
          </div>
        ) : <h2>{tabs[tab] && (tabs[tab]).charAt(0) + (tabs[tab]).slice(1).toLowerCase()} values are not available for this time period.</h2>}

      </div>
    );
  }
}

const mapStateToProps = state => {
  const {
    toQuarter,
    toYear,
    months
  } = state.data;
  return ({
    insightDetail: state.insight.detail,
    endDate: `Q${toQuarter} ${toYear}`,
    months: months,
    hash: state.router.location.hash
  })
}

export default connect(mapStateToProps, {})(Graph)
